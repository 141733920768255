import { template as template_6f9312e4c4b4474da468f59b2378e3a4 } from "@ember/template-compiler";
const FKLabel = template_6f9312e4c4b4474da468f59b2378e3a4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
